import { userAxios } from '@/services'

const ailmentList = async (perPage) => {
    try {
        return await userAxios.get(`ailments/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const ailmentCreate = async (data) => {
    try {
        return await userAxios.post('ailments', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const ailmentUpdate = async (id, data) => {
    try {
        return await userAxios.put(`ailments/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const ailmentDelete = async (id) => {
    try {
        return await userAxios.delete(`ailments/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const ailmentPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`ailments/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const ailmentFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`ailments/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const ailmentFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`ailments/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    ailmentList,
    ailmentCreate,
    ailmentUpdate,
    ailmentDelete,
    ailmentPagination,
    ailmentFilter,
    ailmentFilterPagination
}